.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.status-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.status-item {
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 600px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

.status-name a {
  text-decoration: none;
  color: #333;
}

.status-emoji {
  font-size: 1.5em;
}

.chart-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px; /* Add margin to the bottom of the grid */
}

.chart-container {
  height: 300px; /* Set a fixed height for each chart */
}